import React, { Component } from 'react';

class Header extends Component {
    render() {
        return (
            
            <div className="site-main" id="sTop">
                <div className="site-header">
                    <div className="main-header">
                        <div className="container">
                            <div id="menu-wrapper">
                                <div className="row">
                                    <nav className="navbar navbar-inverse" role="navigation">
                                        <div className="navbar-header">
                                            <button type="button" id="nav-toggle" className="navbar-toggle" data-toggle="collapse" data-target="#main-nav">
                                                <span className="sr-only">Toggle navigation</span>
                                                <span className="icon-bar"></span>
                                                <span className="icon-bar"></span>
                                                <span className="icon-bar"></span>
                                            </button>
                                            <a href="#top" className="navbar-brand">Practical Alchemy</a>
                                            <div id="main-nav" className="collapse navbar-collapse">
                                                <ul className="menu-first nav navbar-nav" style={{ marginRight: '20px'}}>
                                                    <li className="active"><a href="#">Home</a></li>
                                                    <li><a href="#about">About Us</a></li>
                                                    <li><a href="#contact-us">Contact</a></li>                                 
                                                </ul>                                    
                                            </div> 
                                        </div>
                                    </nav>
                                </div> 
                            </div> 
                        </div> 
                    </div> 
                </div> 
            </div>

        )
    }
}
export default Header;