import React, { Component } from 'react';

class Intro extends Component {
    render() {
        return (

            <div className="intro">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h4>Practical Alchemy</h4>
                            <h6>Artisanal Software Engineering, crafted just for you.</h6>
                            <div className="border-button"><a href="#about">Discover More</a></div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}
export default Intro;