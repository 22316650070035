import React, { Component } from 'react';

class About extends Component {
    render() {
        return (

            <section id="about" className="page-section">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="service-item first-service">
                                <div className="icon">
                                    <img src="img/third-service-icon.png" alt="" />
                                </div>
                                <h4>Strategy</h4>
                                <p>Empowering businesses with cutting-edge software solutions and strategic innovation to drive sustainable growth and digital transformation.</p>

                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="service-item second-service">
                                <div className="icon">
                                    <img src="img/first-service-icon.png" alt="" />
                                </div>
                                <h4>Project Management</h4>
                                <p>Delivering seamless software project management with precision, efficiency, and collaboration to ensure your projects are completed on time and within scope.</p>

                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="service-item third-service">

                                <div className="icon">
                                    <img src="img/second-service-icon.png" alt="" />
                                </div>
                                <h4>Software Development</h4>
                                <p>Building innovative, scalable software solutions tailored to your business needs, turning your vision into reality with expert development and cutting-edge technology.</p>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

        )
    }
}
export default About;