import React, { Component } from 'react';

class Banner extends Component {
    render() {
        return (

            <div className="banner">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 col-md-offset-2">
                            <div className="banner-content">
                                <h6>Turning bits into Gold</h6>
                                <h2>Practical Alchemy</h2>
                                <ul className="buttons">
                                    <li>
                                        <div className="primary-button">
                                            <a href="#about">About Us</a>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="secondary-button">
                                            <a href="#contact">Contact</a>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}
export default Banner;