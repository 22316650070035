import React from 'react';

import Header from './Components/header';
import Banner from './Components/banner';
import Intro from './Components/intro';
import About from './Components/about';
import Contact from './Components/contact';
import Footer from './Components/footer';

import './App.css';

 

function App() {
  return (
    <div className="App">
      <Header />
            <Banner />
            <Intro />
            <About />
        <Contact />
		<Footer />
    </div>
  );
}

export default App;
