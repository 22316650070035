import React, { Component } from 'react';
 
class Footer extends Component {
    render() {
        return (
            <footer>
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 col-sm-12">
                                <p>Copyright &copy; {new Date().getFullYear()} Practical Alchemy Ltd</p>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <ul className="social-icons">
                                <li><a target="_blank" href="mailto:hello@practical-alchemy.com">hello@practical-alchemy.com</a></li>
                            </ul>
                        </div>
                        <div className="col-md-2 col-md-offset-2 col-sm-12">
                            <div className="back-to-top">
                                <a href="#top">
                                    <i className="fa fa-angle-up"></i>
                                    back to top
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}
export default Footer;